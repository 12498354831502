const initialState = {
    data: []
}

const getServiceData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SERVICE_DATA":
            return {
                ...state,
                data: action.payload
            }
        default:
            return state
    }
}

export default getServiceData